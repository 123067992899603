const mapContainers = document.querySelectorAll('.Map')

export default function () {
  mapboxgl.accessToken =
    'pk.eyJ1IjoiZGlzY29saWFtIiwiYSI6ImNqazlhbzBuZDF5MXUzcWtrZXJoemFoMWgifQ.ip0c921r8boVdNFOUj41HA'

  mapContainers.forEach((mapContainer) => {
    const mapBox = mapContainer.getElementsByClassName('MapBox'),
      title = mapContainer.getAttribute('data-title'),
      desc = mapContainer.getAttribute('data-desc'),
      lat = mapContainer.getAttribute('data-lat'),
      lon = mapContainer.getAttribute('data-lon'),
      llo = new mapboxgl.LngLat(lon, lat),
      geojson = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [lon, lat],
            },
            properties: {
              title: title,
              description: desc,
            },
          },
        ],
      }

    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/discoliam/clu00a52m005m01qwevnm192j',
      center: llo,
      zoom: 14,
    })

    // add markers to map
    const marker = new mapboxgl.Marker({ color: '#C14F1F' })
      .setLngLat(llo)
      .addTo(map)

    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl())
  })
}
